import { Navigate, Route, Routes } from 'react-router-dom';
import TowerFan from './pages/TowerFan';
import AOS from 'aos';
import { useEffect } from 'react';
import { useWindowSize } from 'react-use';
import AirCooler from './pages/AirCooler';

function App() {
  const { width, height } = useWindowSize();

  AOS.init({ easing: 'ease-in', once: true, offset: 80, duration: 500 });

  useEffect(() => {
    AOS.refreshHard();
  }, [width, height]);

  return (
    <Routes>
      <Route index element={<Navigate to="/tower-fan" />} />
      <Route path="/tower-fan" element={<TowerFan />} />
      {/* <Route path="/air-cooler" element={<AirCooler />} /> */}
    </Routes>
  );
}

export default App;
