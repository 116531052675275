import { Box, Typography } from '@mui/material';
import { maxFontSize, responsiveFontSize } from '../utils/general';

const Benefit = ({ image, title, descs, matches }) => {
  return (
    <Box sx={{ bgcolor: '#FFF6F8', px: 2, py: 4 }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box sx={{ width: 0.45 }}>
          <Box
            data-aos="zoom-in-right"
            data-aos-duration={750}
            component="img"
            src={image}
            sx={{ width: 1, borderRadius: '12px' }}
          />
        </Box>
        <Box sx={{ width: 0.55 }}>
          <Typography
            data-aos="zoom-in-up"
            data-aos-delay={200}
            sx={{
              fontWeight: 700,
              fontSize: matches
                ? responsiveFontSize(13) + 'vw'
                : maxFontSize(13) + 'px',
              color: 'primary.main',
              lineHeight: 1.25,
            }}
          >
            {title}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
            {descs.map((desc, index) => (
              <Box
                data-aos="zoom-in-up"
                data-aos-delay={100 * (index + 1)}
                key={index}
                sx={{ display: 'flex', gap: 1 }}
              >
                <Box
                  sx={{
                    width: matches
                      ? responsiveFontSize(4) + 'vw'
                      : maxFontSize(4) + 'px',
                    minWidth: matches
                      ? responsiveFontSize(4) + 'vw'
                      : maxFontSize(4) + 'px',
                    height: matches
                      ? responsiveFontSize(4) + 'vw'
                      : maxFontSize(4) + 'px',
                    borderRadius: '50%',
                    bgcolor: 'secondary.main',
                    mt: 0.5,
                  }}
                />
                <Typography
                  sx={{
                    color: '#8F8F8F',
                    fontWeight: 500,
                    fontSize: matches
                      ? responsiveFontSize(11) + 'vw'
                      : maxFontSize(11) + 'px',
                  }}
                >
                  {desc}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Benefit;
