import { Box, Typography } from '@mui/material';
import { maxFontSize, responsiveFontSize } from '../utils/general';

const Footer = ({ matches }) => {
  return (
    <Box sx={{ bgcolor: 'primary.main', mt: 4 }}>
      <Box sx={{ p: 2 }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3982.1758733318034!2d98.68787531523199!3d3.5468811515667418!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x303130711c0a7b85%3A0x9cb4d8c73cb03c0b!2sConcepto.id!5e0!3m2!1sen!2sid!4v1655804024324!5m2!1sen!2sid"
          width="100%"
          height="200"
          style={{ border: 0, borderRadius: '20px' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="map"
        ></iframe>
      </Box>
      <Box sx={{ px: 2, mb: 10 }}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: matches
              ? responsiveFontSize(20) + 'vw'
              : maxFontSize(20) + 'px',
            color: '#fff',
          }}
        >
          Danmogot.com
        </Typography>
        <Typography
          sx={{
            fontSize: matches
              ? responsiveFontSize(12) + 'vw'
              : maxFontSize(12) + 'px',
            color: '#fff',
            fontWeight: 500,
          }}
        >
          Gedung Concepto.id <br /> Jl. Alfalah No 19 <br /> Medan Johor <br />
          Sumatera Utara
        </Typography>
      </Box>
      <Box sx={{ bgcolor: '#FBFBFB', p: 2 }}>
        <Typography
          align="center"
          sx={{
            fontSize: matches
              ? responsiveFontSize(12) + 'vw'
              : maxFontSize(12) + 'px',
            color: 'primary.main',
            fontWeight: 500,
            fontStyle: 'italic',
          }}
        >
          A Danmogot.com Company
        </Typography>
      </Box>
    </Box>
  );
};
export default Footer;
