import { Box } from '@mui/material';
import { danmogotLogo } from '../assets';

const Header = ({ image }) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          data-aos="zoom-in-down"
          component="img"
          src={danmogotLogo}
          sx={{ width: '100px', position: 'absolute', top: 16 }}
        />
      </Box>
      <Box
        component="img"
        src={image}
        sx={{
          width: 1,
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px',
          boxShadow: '0 4px 4px 0 rgba(0,0,0,0.25)',
        }}
      />
    </Box>
  );
};
export default Header;
